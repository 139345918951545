<template>
  <v-card tile elevation="1" class="fill-height" min-height="280">
    <MainModal
        :main="{ component: 'TariffBuilding', title: 'Тарифи по будинку' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_by_building)"
        :modal="show_tariff_by_building_dialog"
        @updateItemModal="tariffByBuildingUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title>
          Тарифи по будинках
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-2"
            icon-color="error lighten-1"
            :icon-size="26"
            href="https://youtu.be/8vzhYRNXepc"
        />
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openTariffByBuildingCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий тариф по будинку</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, index) in tariffs" :key="index">
          <v-expansion-panel-header class="header">
              <div class="exp-header-row">
                <div class="exp-header-row-icon">
                  <v-icon size="26">mdi-city</v-icon>
                </div>
                <div class="exp-header-row-content">
                  <div class="exp-header-content-title">
                    {{ item.address }}
                  </div>
                  <div class="exp-header-content-icons">
                    <v-icon v-for="(icon, idx) in item.services" :key="`icn-${idx}`"
                            size="26"
                            :color="getAccountIcon(icon, 'color')"
                            class="ml-3"
                    >
                      {{ getAccountIcon(icon, 'icon') }}
                    </v-icon>
                  </div>
                </div>
              </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="exp-table-row header-row">
              <div class="exp-table-col-icon"/>
              <div class="exp-table-col-service">
                Послуга
              </div>
              <div class="exp-table-col-date-start">
                Початок
              </div>
              <div class="exp-table-col-date-end">
                Закінчення
              </div>
              <div class="exp-table-col-group">
                Група
              </div>
              <div class="exp-table-col-tariff">
                Тариф
              </div>
              <div class="exp-table-col-tariff-second">
                Тариф 2 зона
              </div>
              <div class="exp-table-col-tariff-third">
                Тариф 3 зона
              </div>
            </div>
            <div class="exp-table-row data-row"
                 v-for="(s_item, idx) in item.data"
                 :key="`s-item${idx}`"
                 @click="onTariffByBuildingItemClick(s_item)"
            >
              <div class="exp-table-col-icon">
                <v-icon
                    :color="getAccountIcon(s_item.icon, 'color')"
                >
                  {{ getAccountIcon(s_item.icon, 'icon') }}
                </v-icon>
              </div>
              <div class="exp-table-col-service" :class="!s_item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium'">
                {{ s_item.service_name }}
              </div>
              <div class="exp-table-col-date-start" :class="!s_item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium'">
                {{ s_item.date_start | formatDate }}
              </div>
              <div class="exp-table-col-date-end" :class="!s_item.actual ? 'font-weight-normal grey--text text--darken-1' : 'font-weight-medium'">
                {{ s_item.date_end | formatDate }}
              </div>
              <div class="exp-table-col-group">
                <v-chip small :color="!s_item.actual ? 'grey lighten-2' : 'success'">
                  {{ s_item.tariff_group_type_id !== null ? s_item.tariff_group_type_name : 'Без групи' }}
                </v-chip>
              </div>
              <div class="exp-table-col-tariff" :class="'font-weight-medium'">
                {{ s_item.value_dec }}
              </div>
              <div class="exp-table-col-tariff" :class="'font-weight-medium'">
                {{ s_item.value_dec_second }}
              </div>
              <div class="exp-table-col-tariff" :class="'font-weight-medium'">
                {{ s_item.value_dec_third }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

  </v-card>
</template>

<script>
import {getAccountIcon} from "@/utils/icons";
import {mapActions, mapGetters} from "vuex";
import {FETCH_TARIFF_BY_BUILDING} from "@/store/actions/tariff_by_building";
import MainModal from "@/components/modal/MainModal";

export default {
  name: "TariffBuilding",
  props: {
    legacy: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      tariffs: 'getTarByBuild'
    })
  },
  data() {
    return {
      selected_tariff_by_building: {},
      show_tariff_by_building_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetchTariffs: FETCH_TARIFF_BY_BUILDING
    }),
    getAccountIcon,
    tariffByBuildingUpdateItemModal() {
      this.show_tariff_by_building_dialog = false
      this.selected_tariff_by_building = {}
    },
    openTariffByBuildingCreateDialog() {
      this.selected_tariff_by_building = {is_legacy: this.legacy}
      this.show_tariff_by_building_dialog = true
    },
    onTariffByBuildingItemClick(payload) {
      this.selected_tariff_by_building = JSON.parse(JSON.stringify(payload))
      this.show_tariff_by_building_dialog = true
    },
  },
  created() {
  },
  watch: {
    legacy: {
      immediate: true,
      handler(payload) {
        this.fetchTariffs({legacy: payload})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.exp-header-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .exp-header-row-icon {
    flex: 0 0 60px;
  }

  .exp-header-row-content {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    .exp-header-content-title {
      flex: 1 1 70%;
      text-align: left;
    }
    .exp-header-content-icons {
      flex: 1 1 28%;
      text-align: right;
    }
  }
}

.exp-header-row-open {
  background-color: #4caf50;
}

.exp-table-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #e9e9e9;
  }

  .exp-table-col-icon {
    flex: 0 0 60px;
  }

  .exp-table-col-service {
    flex: 0 0 340px;
  }

  .exp-table-col-date-start {
    flex: 0 0 120px;
    text-align: start;
  }

  .exp-table-col-date-end {
    flex: 0 0 160px;
    text-align: start;
  }

  .exp-table-col-group {
    flex: 0 0 240px;
    text-align: left;
  }

  .exp-table-col-tariff {
    flex: 0 0 130px;
    text-align: start;
  }
  .exp-table-col-tariff-second {
    flex: 0 0 130px;
    text-align: start;
  }
  .exp-table-col-tariff-third {
    flex: 1;
    text-align: start;
  }
}

.data-row {
  border-right: 1px solid #fafafa;
  border-left: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
  font-size: 0.875rem;
}

.header-row {
  background-color: #fafafa;
}

.header {
  &:deep(.v-expansion-panel-header.v-expansion-panel-header--active) {
    background-color: #e6e6e6;
    margin-bottom: 8px;
  }
}
</style>